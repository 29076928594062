<template>
  <div class="login_wrap">
    <div class="loginBox">
      <p class="title">欢迎登录</p>
      <input
        v-model="username"
        placeholder="请输入手机号"
        class="mobile-input input-common"
      />
      <div class="verify-box">
        <input
          v-model="code"
          placeholder="请输入验证码"
          class="verify-input input-common"
          @keyup.enter="login"
        />
        <span v-if="verifyAble" @click="verify" class="send-code common-btn"
          >发送验证码</span
        >
        <span v-if="!verifyAble" class="again-btn common-btn"
          >{{ time }}s 重新发送</span
        >
      </div>
      <div class="btn" @click="login">登录</div>
    </div>
  </div>
</template>

<script lang="js">
import { getPhoneCode, loginAdmin } from '@/apis/login';
import httpRequest from '@/apis/request';


export default {
  data() {
    return {
      username: '',
      code: '',
      verifyAble: true,
      time: 60,
      codeTimer: '',
    }
  },
  methods: {
    async verify() {
      let DEADTIME = 60
      if (!this.username) {
        this.$msgTip('请输入手机号', 'error', 1500)
        return
      }
      this.verifyAble = false
      this.time = DEADTIME
      this.codeTimer = setInterval(() => {
        this.time--
        if (this.time < 0) {
          this.verifyAble = true
          clearInterval(this.codeTimer)
          this.time = DEADTIME
        }
      }, 1000)
      getPhoneCode(
        {
          phone: this.username,
          platform: 'shy_custom',
          sms_type: 'login',
          login_type: '1',
        },
        (res) => {
          if (res.status == 1) {
            this.$msgTip('验证码发送成功', 'success', 1500)
          } else {
            clearInterval(this.codeTimer)
            this.verifyAble = true
            this.$msgTip(res.msg, 'error', 1500)
          }
        }
      )
    },
    async login() {
      if (!this.username || !this.code) {
        this.$msgTip('账号和验证码不能为空', 'error', 1500)
        return
      }
      loginAdmin(
        { phone: this.username, verification_code: this.code },
        (res) => {
          if (res.status == 1) {
            let data = res.data
            this.$msgTip('登录成功', 'success', 1500)
            httpRequest.setOptions({
              token:data.token,
              refreshToken:data.refresh_token
            })
            window.sessionStorage.setItem('_isLogin', true)
            window.sessionStorage.setItem('token', data.token)
            window.localStorage.setItem('token', data.token)
            window.sessionStorage.setItem('isMainAccount', data.mainaccount)
            window.localStorage.setItem('isMainAccount', data.mainaccount)
            this.$store.commit('setUserInfo', data)
            window.localStorage.setItem('phone', this.username)
            if (this.username == '13182776666') {
              this.$router.push('/training')
            } else {
              this.$router.push('/')
            }
          } else {
            this.$msgTip(res.msg, 'error', 1500)
          }
        }
      )
    },
  },
  beforeUnmount() {
    clearInterval(this.codeTimer)
  },
}
</script>

<style lang="less" scoped>
.login_wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(../assets/bg_zf.png) no-repeat center center;
  background-size: 100% 100%;
  .loginBox {
    position: relative;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 15%;
    left: 0;
    box-sizing: border-box;
    width: 330px;
    height: 360px;
    margin: auto;
    padding: 15px;
    text-align: center;
    background: #ffffff;
    border-radius: 5px;
  }

  .title {
    margin: 20px 0 10px 0;
    color: #191e26;
    font-size: 24px;
  }

  .mobile-input {
    width: 90%;
    margin-top: 45px;
  }

  .verify-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 20px auto;
  }

  .verify-input {
    width: 160px;
  }

  .input-common {
    box-sizing: border-box;
    height: 32px;
    padding: 0 16px;
    line-height: 30px;
    border: 1px solid #dcdfe6;
    border-radius: 16px;
    outline: none;
  }

  .input-common::-webkit-input-placeholder {
    color: #ccc4d6;
    font-size: 13px;
  }
  .input-common:-moz-placeholder {
    color: #ccc4d6;
    font-size: 13px;
  }
  .input-common::-moz-placeholder {
    color: #ccc4d6;
    font-size: 13px;
  }
  .input-common:-ms-input-placeholder {
    color: #ccc4d6;
    font-size: 13px;
  }

  .input-common:hover {
    border-color: #c0c4cc;
  }

  .input-common:focus {
    border-color: #409eff;
  }

  .send-code {
    color: #fff;
  }

  .common-btn {
    display: inline-block;
    box-sizing: border-box;
    width: 92px;
    height: 32px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    background: #409eff;
    border: 1px solid #dcdfe6;
    border-radius: 16px;
    cursor: pointer;
  }

  .send-code:hover {
    background: #66b1ff;
    border-color: #409eff;
  }

  .again-btn {
    color: #c7c7c7;
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
    cursor: no-drop;
  }

  .btn {
    width: 90%;
    height: 40px;
    margin-top: 70px;
    margin-left: 5%;
    color: #ffffff;
    font-size: 16px;
    line-height: 40px;
    background: -webkit-linear-gradient(left, #0ca7fe, #2f74ff);
    border: none;
    border-radius: 25px;
    cursor: pointer;
  }
}
</style>
